import { queryCache, useQuery } from 'react-query';
import axios from 'axios';

const fetchThemeData = async (url) => {
  const url1 = `/api/homePage/detailsNew?whiteLabelUrl=${url}`

  const response = await axios.get(url1, {
    headers: {
      'x-auth-token': process.env.JWT_TOKEN
    }
  });
  return response.data ? response.data.sections[0]: null
};

export const useTheme = (url) => {
  return useQuery(['theme', url], () => fetchThemeData(url), {
    refetchOnWindowFocus: false,
    refetchInterval:false,
    refetchOnMount: false,
    refetchIntervalInBackground: false
  });
};

export const setThemeData = (url, newThemeData) => {
  queryCache.setQueryData(['theme', url], newThemeData);
};
