// queryClient.js
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();
const fetchSelectedTheme = async () => {
    return 'dark'
};

export { queryClient, QueryClientProvider, fetchSelectedTheme };

