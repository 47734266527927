import React, {Fragment, useState, useEffect, useContext } from "react";
import { Stack, Typography } from '@mui/material'
import { LineWave } from 'react-loader-spinner'
import { useAppContext } from '../AppContext';
import { useTheme } from '@mui/material/styles';

function loader() {
  const { url, themeData, siteData,  themeLoading, siteLoading, themeError, siteError, changeUrl, updateThemeData, updateSiteData } = useAppContext();

  useEffect(()=>{
    changeUrl(url)
  },[url]);

  const [loading, setLoading] = useState(true)
  const [themeCss, setThemeCss] = useState(null);
  
  useEffect(()=>{
    let custom_css = themeData && themeData ? JSON.parse(themeData && themeData.custom_css): null;
    setThemeCss(custom_css);
      if(siteData){
          setLoading(false)
      }
  }, [])

  return (
    <Typography component="div" display="flex" justifyContent={'center'} alignItems={'center'} sx={{height:'100vh'}}>
      <LineWave
        height="100"
        width="100"
        color={themeCss && themeCss.theme_main_color ? themeCss.theme_main_color : ''}
        ariaLabel="line-wave"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        firstLineColor=""
        middleLineColor=""
        lastLineColor=""
      />
    </Typography>
  )
}

export default loader

