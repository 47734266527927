import { queryCache, useQuery } from 'react-query';
import axios from 'axios';

const fetchSiteData = async (url) => {
  const url1 = `/api/homePage/detailsNew?whiteLabelUrl=${url}`
  const response = await axios.get(url1, {
    headers: {
      'x-auth-token': process.env.JWT_TOKEN
    }
  });
  return response.data
};

export const useSite = (url) => {
  return useQuery(['site', url], () => fetchSiteData(url), {
    refetchOnWindowFocus: false,
    refetchInterval:false,
    refetchOnMount: false,
    refetchIntervalInBackground: false
  });
};

export const setSiteData = (url, newThemeData) => {
  queryCache.setQueryData(['site', url], newThemeData);
};
