import { useState, useEffect, useContext} from "react"
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { lightTheme, darkTheme, blueTheme } from "../src/theme" 
import {styles} from '../styles/globals.css'
import Head from "next/head";
import { useRouter } from 'next/router';
import PageLoader from '@/components/loader'
import { queryClient, QueryClientProvider } from '@/src/queryClient'
import { AppProvider } from '../AppContext';
import {ReactQueryDevtools} from 'react-query/devtools'
import axios from "axios";
import { red, green } from "@mui/material/colors";


function MyApp({ Component, pageProps }) {
  const [selectedTheme, setSelectedTheme] = useState(3) 
  const [selectedThemeValue, setSelectedThemeValue] = useState(darkTheme) 
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const Router = useRouter();
    
  const black = "#000";
  const white = "#fff";
  const gray = "#484848";
  const light = "#545454";
  const borderColor = '#3F3F3F'


  
  const [primaryColor, setPrimaryColor] = useState('#ff5500');
  const [backgroundColor, setBackgroundColor] = useState('#000');
  const [cardColor, setCardColor] = useState('#888');
  const [textColor, setTextColor] = useState('#fff');

  useEffect(()=>{
    const url1 = `/api/homePage/detailsNew?whiteLabelUrl=${pageProps.url}`
    getFirstData(url1);
  },[]);

  const getFirstData = async(url1) => {
    setLoading(true)
    await axios.get(url1, {
      headers: {
        'x-auth-token': process.env.JWT_TOKEN
      }
    }).then((response)=>{
      setData(response.data.club_inner ? response.data.club_inner[0] : null);
      setSelectedTheme(response.data.club_inner ? response.data.club_inner[0].restaurant_landing_theme : 3);
      setPrimaryColor(response.data.sections ? JSON.parse(response.data.sections[0].custom_css).theme_main_color : '#ff5500')
      setBackgroundColor(response.data.sections ? JSON.parse(response.data.sections[0].custom_css).custom_theme_color : '#000')
      setCardColor(response.data.sections ? JSON.parse(response.data.sections[0].custom_css).custom_theme_color2 : '#888')
      setTextColor(response.data.sections ? JSON.parse(response.data.sections[0].custom_css).custom_theme_color3 : '#fff')
      if(response.data.club_inner[0].restaurant_landing_theme == 2){
        setSelectedThemeValue(lightTheme)
      }else if(response.data.club_inner[0].restaurant_landing_theme == 3){
        setSelectedThemeValue(darkTheme)
      }else if(response.data.club_inner[0].restaurant_landing_theme == 4){
        setSelectedThemeValue(blueTheme)
      }else{
        setSelectedThemeValue(customTheme)
      }
      setLoading(false);
      // localStorage.setItem('selectedTheme', response.data.club_inner ? response.data.club_inner[0].restaurant_landing_theme : 3);
    })
  }

  const customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 991,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      mode: 'dark',
      common: {
        black: `${black}`,
        white: `${white}`,
        gray: `${gray}`,
        light: `${light}`,
        green: `${green}`,
      },
      primary: {
        main: `${primaryColor}`,
        contrastText: `${textColor}`,
        arrowColor: '#1B1818',
        location: '#909090',
        title: '#F6F6F6',
        subTitle: '#909090',          
        dates: '#DFDFDF',    
      },
      secondary: {
        dark: '#ffffff90',
        main: '#ffffff90',
        light: '#ffffff90',
      },
      error: {
        main: '#19857b',
      },
      background: {
        default: `${backgroundColor} !important`,
        alternate: `${cardColor}`,
        secondary: '#343434',
        tickets: '#4C4C4C',
        ticket: '#5D5D5D',
        signInCard:'#1D1D1D',
        borderColor: '#5D5D5D' ,
        footer: '#343434',
        header: '#343434'  
      },
      green: {
        main: `${green}`,
      },
      light: {
        main: `${light}`,
      },
      error: {
        main: red.A400,
      },
      borderLight: {
        main: `${borderColor}`
      }        
    },
    typography: {
      fontFamily: `"DM Sans", sans-serif`,
      fontSize: 14,
      fontWeight: 'regular',   
      h1: {
        fontSize: 30,  
        fontWeight: 'bold',         
      },
      h2: {
        fontSize: 28,  
        fontWeight: 'bold',         
      },
      h3: {
        fontSize: 26,  
        fontWeight: 'medium',         
      },
      h4: {
        fontSize: 24,  
        fontWeight: 'medium',         
      },
      h5: {
        fontSize: 22,  
        fontWeight: 'light',         
      },
      h6: {
        fontSize: 20,  
        fontWeight: 'light',         
      },
      subtitle1: {
        fontSize: 12,
      },        
    },
    components: {
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            fontSize: '10px'
          }
        }
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            a:{
              textDecoration:'none'
            }
          }
        }
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontSize: '10px',
            borderRadius: '20px'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: 12,
            borderRadius: 20
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            background: 'transparent',
            maxWidth: '100%',
          }
        }
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            "&:last-child": {
              paddingBottom: '0 !important',
              marginBottom: 0
            }
          },
          '& .form-control': {
            background: 'transparent'
          }
        }
      },
      MuiList: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
          }
        }
      },
      MuiMenuItem:{
        styleOverrides:{
          root: {
            borderRadius: 0,
            padding: 12,
            background: '#1D1D1D',
            ":hover": {
              backgroundColor: "#343434",            
            },          
            "&.Mui-selected":{
              backgroundColor: "#343434",            
              ":hover": {
                backgroundColor: "#343434",              
              },
            }
          }
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            ':first-of-type': {
              borderRadius: 8,
            }
          }
        }
      },
      MuiInputLabel: {
        overrides: {
          root: {
            color: `${light}`,
          },
        },
      },
    },
  });

  const styles = {
    body: {
      backgroundColor: `${backgroundColor}`,
      contrastText: `${textColor}`,
    },
  };

  useEffect(()=>{
    document.body.style.backgroundColor = styles.body.backgroundColor;
    document.body.style.color = styles.body.contrastText;
  },[backgroundColor, primaryColor, textColor, cardColor]);

  useEffect(() => {
    if(data && data.events_only == 1 && (Router.pathname == '/events' || Router.pathname == '/deals' || Router.pathname == '/about' || Router.pathname == '/contact') ){
      Router.push('/')
    }
  },[data,Router.events]);

  useEffect(() => {
    Router.events.on('routeChangeStart', () => setLoading(true));
    Router.events.on('routeChangeComplete', () => setLoading(false));
    Router.events.on('routeChangeError', () => setLoading(true));
    return () => {
      Router.events.off('routeChangeStart', () => setLoading(true));
      Router.events.off('routeChangeComplete', () => setLoading(false));
      Router.events.off('routeChangeError', () => setLoading(true));
    };
  }, [Router.events]);
  
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
        </Head>
          <ThemeProvider theme={selectedThemeValue}>
            {loading ? (
              <PageLoader />
            ):(
              <Component {...pageProps} />
            )}
          </ThemeProvider>
      </AppProvider>
      <ReactQueryDevtools position="bottom-right"/>
    </QueryClientProvider>
  ) 
}

export default MyApp


