import { createTheme } from '@mui/material/styles';
import { red, green } from "@mui/material/colors";

const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightMedium = 600;
const fontWeightBold = 800;

const black = "#000";
const white = "#fff";
const gray = "#484848";
const light = "#545454";
const borderColor = '#3F3F3F'


const lightPalette = {
  mode: 'light',
  common: {
    black: `${black}`,
    white: `${white}`,
    gray: `${gray}`,
    light: `${light}`,
    green: `${green}`,
  },
  primary: {
    main: '#61FC6E',
    arrowColor: '#1B1818',
    location: '#909090',
    title: '#777',
    subTitle: '#777',          
    dates: '#000',          
  },
  secondary: {
    dark: '#000000090',
    main: '#000000090',
    light: '#000000090',
  },
  error: {
    main: '#19857b',
  },
  background: {
    alternate: '#efefef',
    contrastText:'#00000060',
    secondary: '#fafafa',
    tickets: '#f5f5f5',
    ticket: '#eeeeee',
    signInCard: '#ffffff',
    borderColor: '#efefef',
    footer: '#e2e2e2',
    header: '#e2e2e2'
  },
  green: {
    main: `${green}`,
  },
  light: {
    main: `${light}`,
  },
  error: {
    main: red.A400,
  },
  borderLight: {
    main: `${borderColor}`,
  },
};

const darkPalette = {
  mode: 'dark',
  common: {
    black: `${black}`,
    white: `${white}`,
    gray: `${gray}`,
    light: `${light}`,
    green: `${green}`,
  },
  primary: {
    main: '#61FC6E',
    contrastText: '#fffffd',
    arrowColor: '#1B1818',
    location: '#909090',
    title: '#F6F6F6',
    subTitle: '#909090',          
    dates: '#DFDFDF',    
  },
  secondary: {
    dark: '#ffffff90',
    main: '#ffffff90',
    light: '#ffffff90',
  },
  error: {
    main: '#19857b',
  },
  background: {
    default: '#151414',
    alternate: '#1D1D1D',
    secondary: '#343434',
    tickets: '#4C4C4C',
    ticket: '#5D5D5D',
    signInCard:'#1D1D1D',
    borderColor: '#5D5D5D' ,
    footer: '#343434',
    header: '#343434'  
  },
  green: {
    main: `${green}`,
  },
  light: {
    main: `${light}`,
  },
  error: {
    main: red.A400,
  },
  borderLight: {
    main: `${borderColor}`
  }        
};

const bluePalette = {
  mode: 'dark',
  common: {
    black: `${black}`,
    white: `${white}`,
    gray: `${gray}`,
    light: `${light}`,
    green: `${green}`,
  },
  primary: {
    main: '#61FC6E',
    contrastText: '#fffffd',
    arrowColor: '#1B1818',
    location: '#909090',
    title: '#dfe4f8',
    subTitle: '#909090',          
    dates: '#DFDFDF',    
  },
  secondary: {
    dark: '#ffffff90',
    main: '#ffffff90',
    light: '#ffffff90',
  },
  error: {
    main: '#19857b',
  },
  background: {
    default: '#040e38',
    alternate: '#082448',
    secondary: '#343434',
    tickets: '#4C4C4C',
    ticket: '#5D5D5D',
    signInCard:'#1D1D1D',
    borderColor: '#5f657d',
    footer: '#082448',
    header: '#082448'
  },
  green: {
    main: `${green}`,
  },
  light: {
    main: `${light}`,
  },
  error: {
    main: red.A400,
  },
  borderLight: {
    main: `${borderColor}`
  }        
};

const lightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 991,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: lightPalette,
  typography: {
    fontFamily: `"DM Sans", sans-serif`,
    fontSize: 14,
    fontWeight: fontWeightRegular,   
    h1: {
      fontSize: 30,  
      fontWeight: fontWeightBold,         
    },
    h2: {
      fontSize: 28,  
      fontWeight: fontWeightBold,         
    },
    h3: {
      fontSize: 26,  
      fontWeight: fontWeightMedium,         
    },
    h4: {
      fontSize: 24,  
      fontWeight: fontWeightMedium,         
    },
    h5: {
      fontSize: 22,  
      fontWeight: fontWeightLight,         
    },
    h6: {
      fontSize: 20,  
      fontWeight: fontWeightLight,         
    },
    subtitle1: {
      fontSize: 12,
    },        
  },
  components: {
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          fontSize: '10px'
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: '10px',
          borderRadius: '20px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 12,
          borderRadius: 20
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: 'transparent',
          maxWidth: '100%',
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: '0 !important',
            marginBottom: 0
          }
        }
      },
      '& .form-control': {
        background: 'transparent'
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
        }
      }
    },
    MuiMenuItem:{
      styleOverrides:{
        root: {
          borderRadius: 0,
          padding: 12,
          background: '#1D1D1D',
          ":hover": {
            backgroundColor: "#343434",            
          },          
          "&.Mui-selected":{
            backgroundColor: "#343434",            
            ":hover": {
              backgroundColor: "#343434",              
            },
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ':first-of-type': {
            borderRadius: 8,
          }
        }
      }
    },
    MuiInputLabel: {
      overrides: {
        root: {
          color: `${light}`,
        },
      },
    },
  },
});

const darkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 991,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: darkPalette,
  typography: {
    fontFamily: `"DM Sans", sans-serif`,
    fontSize: 14,
    fontWeight: fontWeightRegular,   
    h1: {
      fontSize: 30,  
      fontWeight: fontWeightBold,         
    },
    h2: {
      fontSize: 28,  
      fontWeight: fontWeightBold,         
    },
    h3: {
      fontSize: 26,  
      fontWeight: fontWeightMedium,         
    },
    h4: {
      fontSize: 24,  
      fontWeight: fontWeightMedium,         
    },
    h5: {
      fontSize: 22,  
      fontWeight: fontWeightLight,         
    },
    h6: {
      fontSize: 20,  
      fontWeight: fontWeightLight,         
    },
    subtitle1: {
      fontSize: 12,
    },        
  },
  components: {
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          fontSize: '10px'
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: '10px',
          borderRadius: '20px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 12,
          borderRadius: 20
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: 'transparent',
          maxWidth: '100%',
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: '0 !important',
            marginBottom: 0
          }
        }
      },
      '& .form-control': {
        background: 'transparent'
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
        }
      }
    },
    MuiMenuItem:{
      styleOverrides:{
        root: {
          borderRadius: 0,
          padding: 12,
          background: '#1D1D1D',
          ":hover": {
            backgroundColor: "#343434",            
          },          
          "&.Mui-selected":{
            backgroundColor: "#343434",            
            ":hover": {
              backgroundColor: "#343434",              
            },
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ':first-of-type': {
            borderRadius: 8,
          }
        }
      }
    },
    MuiInputLabel: {
      overrides: {
        root: {
          color: `${light}`,
        },
      },
    },
  },
});

const blueTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 991,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: bluePalette,
  typography: {
    fontFamily: `"DM Sans", sans-serif`,
    fontSize: 14,
    fontWeight: fontWeightRegular,   
    h1: {
      fontSize: 30,  
      fontWeight: fontWeightBold,         
    },
    h2: {
      fontSize: 28,  
      fontWeight: fontWeightBold,         
    },
    h3: {
      fontSize: 26,  
      fontWeight: fontWeightMedium,         
    },
    h4: {
      fontSize: 24,  
      fontWeight: fontWeightMedium,         
    },
    h5: {
      fontSize: 22,  
      fontWeight: fontWeightLight,         
    },
    h6: {
      fontSize: 20,  
      fontWeight: fontWeightLight,         
    },
    subtitle1: {
      fontSize: 12,
    },        
  },
  components: {
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          fontSize: '10px'
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          a:{
            textDecoration:'none'
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: '10px',
          borderRadius: '20px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 12,
          borderRadius: 20
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: 'transparent',
          maxWidth: '100%',
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: '0 !important',
            marginBottom: 0
          }
        }
      },
      '& .form-control': {
        background: 'transparent'
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
        }
      }
    },
    MuiMenuItem:{
      styleOverrides:{
        root: {
          borderRadius: 0,
          padding: 12,
          background: '#1D1D1D',
          ":hover": {
            backgroundColor: "#343434",            
          },          
          "&.Mui-selected":{
            backgroundColor: "#343434",            
            ":hover": {
              backgroundColor: "#343434",              
            },
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          ':first-of-type': {
            borderRadius: 8,
          }
        }
      }
    },
    MuiInputLabel: {
      overrides: {
        root: {
          color: `${light}`,
        },
      },
    },
  },
});



export { lightTheme, darkTheme, blueTheme};
