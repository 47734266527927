import React, { createContext, useContext, useState } from 'react';
import { useTheme, setThemeData } from '@/src/themeQueries';
import { useSite, setSiteData } from '@/src/siteQueries';
import { useRouter } from 'next/router';

const AppContext = createContext();

const AppProvider = ({ children }) => {

  const { asPath } = useRouter();
  const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';
  const _URL = `${origin}`;
  // console.log(URL);

  const [url, setUrl] = useState(_URL == 'localhost:3000' ? 'demo1.clubr.co.in': _URL);
  const { data: themeData, error:themeError, isLoading:themeLoading } = useTheme(url);
  const { data: siteData, error:siteError, isLoading:siteLoading } = useSite(url);

  const changeUrl = (url) => {
    setUrl(url);
  };

  const updateThemeData = (newThemeData) => {
    setThemeData(url, newThemeData);
  };

  const updateSiteData = (newThemeData) => {
    setSiteData(url, newThemeData);
  };

  const value = {
    url,
    themeData,
    siteData,
    themeLoading,
    siteLoading,
    themeError,
    siteError,
    changeUrl,
    updateThemeData,
    updateSiteData,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

export { AppProvider, useAppContext };
